import React from 'react';
import { cn } from '@/lib/utils';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const Container: React.FC<ContainerProps> = ({ 
  children, 
  size = 'lg',
  className,
  ...props 
}) => {
  const maxWidths = {
    sm: 'max-w-2xl',
    md: 'max-w-4xl',
    lg: 'max-w-6xl',
    xl: 'max-w-[2000px]'
  };

  return (
    <div 
      className={cn(
        'w-full mx-auto px-4',
        maxWidths[size],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
