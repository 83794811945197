import React from 'react';

interface PasswordStrengthIndicatorProps {
  password: string;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ password }) => {
  const calculateStrength = (): { strength: number; message: string } => {
    let strength = 0;
    const checks = {
      length: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecial: /[^A-Za-z0-9]/.test(password),
    };

    strength = Object.values(checks).filter(Boolean).length;

    const messages = {
      0: 'Very Weak',
      1: 'Weak',
      2: 'Fair',
      3: 'Good',
      4: 'Strong',
      5: 'Very Strong',
    };

    return {
      strength,
      message: messages[strength as keyof typeof messages],
    };
  };

  const { strength, message } = calculateStrength();
  const strengthPercent = (strength / 5) * 100;

  return (
    <div className="mt-2 space-y-2">
      <div className="h-1 w-full bg-zinc-700 rounded-full overflow-hidden">
        <div
          className={`h-full transition-all duration-300 ${
            strength <= 1
              ? 'bg-red-500'
              : strength <= 2
              ? 'bg-orange-500'
              : strength <= 3
              ? 'bg-yellow-500'
              : 'bg-green-500'
          }`}
          style={{ width: `${strengthPercent}%` }}
        />
      </div>
      <div className="flex justify-between text-xs">
        <span className={`
          ${strength <= 1 ? 'text-red-500' : ''}
          ${strength === 2 ? 'text-orange-500' : ''}
          ${strength === 3 ? 'text-yellow-500' : ''}
          ${strength >= 4 ? 'text-green-500' : ''}
        `}>
          {message}
        </span>
        <span className="text-zinc-400">
          {strength}/5 requirements met
        </span>
      </div>
      <ul className="text-xs space-y-1 text-zinc-400">
        <li className={password.length >= 8 ? "text-green-500" : ""}>
          • At least 8 characters
        </li>
        <li className={/[A-Z]/.test(password) ? "text-green-500" : ""}>
          • One uppercase letter
        </li>
        <li className={/[a-z]/.test(password) ? "text-green-500" : ""}>
          • One lowercase letter
        </li>
        <li className={/[0-9]/.test(password) ? "text-green-500" : ""}>
          • One number
        </li>
        <li className={/[^A-Za-z0-9]/.test(password) ? "text-green-500" : ""}>
          • One special character
        </li>
      </ul>
    </div>
  );
};

export default PasswordStrengthIndicator;
