import React from 'react';
import { cn } from '@/lib/utils';

type TypographyVariant = 'display' | 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'large' | 'small' | 'muted';
type HTMLElementType = keyof JSX.IntrinsicElements;

interface TypographyProps extends Omit<React.HTMLAttributes<HTMLElement>, 'as'> {
  children: React.ReactNode;
  variant?: TypographyVariant;
  as?: HTMLElementType;
  gradient?: boolean;
}

const variantMapping: Record<TypographyVariant, HTMLElementType> = {
  display: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body: 'p',
  large: 'p',
  small: 'p',
  muted: 'p'
};

const variantStyles: Record<TypographyVariant, string> = {
  display: 'text-5xl md:text-6xl lg:text-7xl font-bold tracking-tight',
  h1: 'text-4xl md:text-5xl lg:text-6xl font-bold',
  h2: 'text-3xl md:text-4xl font-bold',
  h3: 'text-2xl md:text-3xl font-semibold',
  h4: 'text-xl md:text-2xl font-semibold',
  body: 'text-base leading-relaxed',
  large: 'text-lg md:text-xl leading-relaxed',
  small: 'text-sm leading-relaxed',
  muted: 'text-sm text-zinc-400 leading-relaxed'
};

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body',
  as,
  gradient = false,
  className,
  ...props
}) => {
  const Component = (as || variantMapping[variant]) as React.ElementType;

  return (
    <Component
      className={cn(
        variantStyles[variant],
        gradient && 'text-gradient',
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
export default Typography;
